<template>
  <div class="group">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入拼团活动名称"></el-input>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <el-button type="primary">
          <router-link to="/market/packageDetails" class="goto">新增拼团</router-link>
        </el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="活动名称" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="hour" label="有效期（小时）" width="120"></el-table-column>
        <el-table-column prop="group_num" label="成团人数" width="100"></el-table-column>
        <el-table-column prop="goods_name" label="拼团课程" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="group_price" label="团购价格" width="100"></el-table-column>
        <el-table-column prop="created" label="创建时间"></el-table-column>
        <el-table-column prop="is_enable" label="是否发布" width="100"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" @click="handleAlter(scope.row)">{{scope.row.is_enable === '未发布' ? '发' : '取消发'}}布</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getGroupList, shopGroupStatus, shopDelGroup } from '../../../request/api'
import MyPagination from '../../../components/MyPagination.vue'

export default {
  inject: ['reload'],
  components: {
    MyPagination
  },
  data () {
    return {
      input: '',
      tableData: [],
      query_info: {
        chain_id: '',
        limit: 10,
        page: 1
      },
      total: 0,
      loading: false
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    // 搜索按钮
    searchInp () {
      this.query_info.page = 1
      this.tableData = []
      this.getGroup()
    },
    // 编辑
    handleEdit (row) {
      this.$router.push(
        {
          path: '/market/packageDetails',
          query: {
            shop_group_id: row.shop_group_id
          }
        }
      )
    },
    // 发布活动
    async handleAlter (row) {
      let postData = {
        goods_id: row.goods_id,
        shop_group_id: row.shop_group_id,
        is_enable: row.is_enable === '未发布' ? 1 : 0
      }
      const res = await shopGroupStatus(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message({
        type: 'success',
        message: '修改成功！'
      })
      this.reload()
    },
    // 删除
    handleDelete (row) {
      this.$confirm('是否删除该拼团？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let postData = {
          shop_group_id: row.shop_group_id
        }
        const res = await shopDelGroup(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '删除成功！'
        })
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async getGroup () {
      try {
        this.loading = true
        const res = await getGroupList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, this.input)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.tableData = res.data.data.groupList.filter(element => {
          return element.is_enable === 0 ? element.is_enable = '未发布' : element.is_enable = '已发布'
        })
        this.total = res.data.data.totalNumber
      } finally {
        this.loading = false
      }
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.tableData = []
      this.getGroup()
    }
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  },
  mounted () {
    this.getGroup()
  }
}
</script>

<style lang="less" scoped>
.group {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 20px;
      .goto {
        color: #fff;
      }
    }
  }
  .wrapper {
    margin: 20px 0;
  }
}
</style>